import React from 'react';

const ResumeSection = () => {
    return (
        <section className="resume" id="resume">

           {/* Work Experience Section */}
           <div className="resume-subsection">
                <h3 className="section-title">Work</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>IT Systems Engineer</h4>
                        <p>Gopuff - Remote</p>
                        <p>Jan 2022 - Mar 2025 · 3 yrs 3 mos</p>
                        <ul>
                            <li>Developed and implemented new automations, workflows, and standardized escalation procedures to streamline IT operations. </li>
                            <li>Utilized cloud application integration with Single Sign-On and SCIM provisioning for identity management to enhance user experience and security of 40+ SAML 2.0 Web applications into OKTA and Azure Entra ensuring compliance with PCI/SOX.</li>
                            <li>Manage on-premises VMware ESXi Windows environment as well as Azure, implementing a new backup solution utilizing Veeam, storage array, and Azure Blob storage and disaster recovery plan to ensure business continuity. </li>
                            <li>Manage OKTA Identity platform, Azure Entra ID, and Active Directory.</li>
                            <li>Resolving complex issues and optimizing license costs through rightsizing and proposal development.</li>
                            <li>Cost Analysis switching on Prem infrastructure to Azure or Datacenter location.</li>
                            <li>Disaster Recovery development.</li>
                            <li> Through software consolidation, $750,000 annually has been saved.</li>
                            <li>Escalation/mentor to IT Support team at all levels.</li>

                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>IT Support Specialist</h4>
                        <p>Malwarebytes - Sunnyvale, California, United States</p>
                        <p>Feb 2020 - Jan 2022 · 2 yrs</p>
                        <ul>
                            <li>Delivered exceptional user support (all levels, incl. executives) across platforms (mobile, remote, in-person).</li>
                            <li>Managed user lifecycles with Azure, O365, Okta, & Active Directory. Ensured smooth email (Exchange Online) & knowledge of cloud platforms (GCP).</li>
                            <li>Supported users across platforms (Windows Server, Printer Server), deployed & managed virtual environments (vSphere), and facilitated seamless MDM (JAMF, Intune). Led SaaS initiatives (Zeplin, Confluence).</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>System Support Analyst</h4>
                        <p>HCL Technologies @ LinkedIn HQ - Sunnyvale, CA</p>
                        <p>Nov 2018 - Jan 2020 · 1 yr 3 mos</p>
                        <ul>
                            <li>Delivered exceptional in-person and remote support (L2) across Mac/Windows/Linux platforms, including onboarding, troubleshooting, and resolving escalations. </li>
                            <li>Maintained user experience through ticketing systems (ServiceNow), documentation creation, and configuration of access management solutions (Active Directory, AirWatch, VIP, Okta</li>
                            <li>Managed device lifecycles through imaging/re-imaging, inventory control, and mobile device support (iOS/Android).</li>
                            <li>Utilized scripting (Bash) and automation tools to streamline deployments and optimize system performance (CPPM administration).</li>
                            <li>Effectively resolved complex technical issues for users and engineers with tools like JIRA and Confluence. </li>
                            <li>Championed strong communication and collaboration by assisting with Go-Live projects and beta testing initiatives.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>IT Field Services Technician II</h4>
                        <p>Corning Incorporated · Full-time | Jan 2016 - Oct 2018 · 2 yrs 10 mos | Union City, California</p>
                        <ul>
                            <li>User Support & Automation: Supported 1,200+ end-users across diverse platforms, implementing automation scripts that reduced IT service request resolution times by 20%.</li>
                            <li>Server & Network Management: Managed a variety of server environments, including Windows Server, Active Directory, VMware, and Linux, ensuring optimal performance and security.</li>
                            <li>Process Improvement: Championed continuous improvement by developing automated processes for server deployments, backups, and user management, significantly reducing manual workloads.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Technician</h4>
                        <p>Circle of Help Foundation · Full-time | Jul 2010 - Sep 2012 · 2 yrs 3 mos | Greater Los Angeles Area</p>
                        <ul>
                            <li>Identity Management: Active Directory.</li>
                            <li>Server Administration: Windows Servers, Domain Controllers, DNS, Printer Server, MSSQL, File Server, Peachtree Server.</li>
                            <li>Virtualization Administration: vSphere.</li>
                            <li>Network Administration: Web Filter Server.</li>
                            <li>Provided level 1 to level 3 end-user support, responded to daily technical issues, and maintained company assets including laptops, desktops, Windows OS, Apple OSX, iOS, and Android devices.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Analyst</h4>
                        <p>Center for Elders Independence · Full-time | Jun 2009 - Sep 2009 · 4 mos | Oakland, California</p>
                        <ul>
                            <li>Provided level 1 to level 2 end-user support, retrieved and repaired PCs, installed new hardware, maintained updates, and imaged new OS.</li>
                            <li>Assisted in deploying new PC machines, servers, and a new phone system during an infrastructure upgrade, and responded to IT support tickets.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Assistant</h4>
                        <p>Diablo Magazine · Internship | Aug 2007 - Jun 2008 · 11 mos | Walnut Creek, California</p>
                        <ul>
                            <li>Provided level 1 end-user support, maintained asset management system, responded to technical issues, and managed Active Directory.</li>
                            <li>Supported Windows OS and Apple OSX environments.</li>
                        </ul>
                    </div>

                    <div className="resume-item">
                        <h4>Information Technology Support Assistant</h4>
                        <p>ACALANES UNION HIGH SCHOOL DISTRICT · Internship | Aug 2007 - Jan 2008 · 6 mos | Walnut Creek, California, United States</p>
                        <ul>
                            <li>Assisted IT field services with level 1 support at Las Lomas High School.</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Certifications Section */}
            <div className="resume-subsection">
                <h3 className="section-title">Certifications</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>OKTA Certified Administrator</h4>
                        <p>OKTA, 2023</p>
                    </div>
                    <div className="resume-item">
                        <h4>OKTA Certified Professional</h4>
                        <p>OKTA, 2022</p>
                    </div>
                    <div className="resume-item">
                        <h4>Certified ScrumMaster (CSM)</h4>
                        <p>Scrum Alliance, 2021</p>
                    </div>
                    <div className="resume-item">
                        <h4>Certified JAMF Associate</h4>
                        <p>JAMF, 2019</p>
                    </div>
                </div>
            </div>

            {/* Education Section */}
            <div className="resume-subsection">
                <h3 className="section-title">Education</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>Bachelors of Science, Information Technology</h4>
                        <p>Daytona State College, 2024 - Present</p>
                    </div>
                    <div className="resume-item">
                        <h4>Associates of Science, Computer Network Technology</h4>
                        <p>Diablo Valley College, 2014 - 2018</p>
                    </div>
                </div>
            </div>

           {/* Projects Section 
            <div className="resume-subsection">
                <h3 className="section-title">Projects</h3>
                <div className="section-content">
                    <div className="resume-item">
                        <h4>Project Name 1</h4>
                        <p>Brief description of the project and your role.</p>
                    </div>
                    {
                    <div className="resume-item">
                        <h4>Project Name 2</h4>
                        <p>Brief description of the project and your role.</p>
                    </div>
                </div>
            </div>*/}
        </section>
    );
}

export default ResumeSection;
