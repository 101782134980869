import React from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li>
                    <Link to="hero" smooth={true} duration={500}>Home</Link>
                </li>
                <li>
                    <Link to="about" smooth={true} duration={500}>About</Link>
                </li>
                <li>
                    <Link to="resume" smooth={true} duration={500}>Resume</Link>
                </li>
               {/* <li>
                    <Link to="portfolio" smooth={true} duration={500}>Portfolio</Link>
                </li> */}
            </ul>
        </nav>
    );
}

export default Navbar;

