import React from 'react';
import { FaArrowDown, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-scroll';
import TypingEffect from 'react-typing-effect';

const HeroSection = () => {
    return (
        <section className="hero" id="hero">
            <div className="hero-content">
                <TypingEffect
                    text={["Hello, is it Gene you are looking for?"]}
                    speed={100}
                    eraseDelay={3000}
                    className="typing-effect"
                />
                <p>Welcome to my resume. Built on REACT</p>
                <a href="https://www.linkedin.com/in/eugene-rudnitsky/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="linkedin-icon" />
                </a>
            </div>
            <Link to="about" smooth={true} duration={500} className="arrow">
                <FaArrowDown />
            </Link>
        </section>
    );
}

export default HeroSection;